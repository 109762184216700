import { compare, CompareOperator } from "compare-versions"
import platform from "platform"
import { DevicePostureOsAndBrowserType, DevicePostureType } from "src/services/api/swrHooks/useDevicePostures"

const defaultPostureCheck = {
  anyVersion: false,
  id: "",
  isEnabled: false,
  name: "",
  operator: "",
  version: "",
}

export const checkDevicePostureForBrowser = (
  serviceDevicePosture: DevicePostureType,
): {
  devicePostureNotMatchingBrowser: DevicePostureOsAndBrowserType[]
} => {
  const browserInfo = { browser: platform.name, browserVersion: platform.version }
  const { browserCheck, isBrowserEnabled } = serviceDevicePosture

  if (!isBrowserEnabled)
    return {
      devicePostureNotMatchingBrowser: [],
    }

  const browserMatchingCurrentBrowser = browserCheck.find((browser) => {
    return browserInfo?.browser?.includes(browser?.name)
  })

  if (browserMatchingCurrentBrowser && !browserMatchingCurrentBrowser?.isEnabled)
    return {
      devicePostureNotMatchingBrowser: [defaultPostureCheck],
    }

  if (browserMatchingCurrentBrowser) {
    const operatorValue = browserMatchingCurrentBrowser.operator === "==" ? "=" : browserMatchingCurrentBrowser.operator
    const browserVersionArrayfied = browserInfo.browserVersion.split(".")

    if (!browserMatchingCurrentBrowser.anyVersion) {
      if (
        !compare(
          browserVersionArrayfied.length <= 1
            ? browserInfo.browserVersion || ""
            : browserVersionArrayfied.slice(0, 1).join("."),
          browserMatchingCurrentBrowser.version.split(".").slice(0, 1).join(".") || "",
          operatorValue as CompareOperator,
        )
      ) {
        return {
          devicePostureNotMatchingBrowser: [browserMatchingCurrentBrowser],
        }
      }
    }
    return {
      devicePostureNotMatchingBrowser: [],
    }
  }
  return {
    devicePostureNotMatchingBrowser: [defaultPostureCheck],
  }
}
