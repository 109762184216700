import { timeIntervalOptions } from "src/constants/dropdownsData"
import EnRadioGroup from "src/shared/components/FormComponents/EnRadioGroup"
import { ChartsTimeIntervalFilterType } from "src/utils"
import { useActivityLogsTimeFilterStyles } from "./ActivityLogsTimeFilter.styles"

interface ActivityLogsTimeFilterProps {
  onChange: (event: any) => void
  value: ChartsTimeIntervalFilterType
}

const ActivityLogsTimeFilter: React.FC<ActivityLogsTimeFilterProps> = ({ onChange, value }) => {
  const classes = useActivityLogsTimeFilterStyles()
  return (
    <div className={classes.root}>
      <span className={classes.noWrap}>Show Data of:</span>
      <EnRadioGroup value={value} onChange={onChange} name="" options={timeIntervalOptions} />
    </div>
  )
}

export default ActivityLogsTimeFilter
