import { ENTab } from "en-react/dist/src/components/Tab"
import { ENTabPanel } from "en-react/dist/src/components/TabPanel"
import { ENTabs } from "en-react/dist/src/components/Tabs"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import { RootState } from "src/store"
import themeData from "src/theme"
import { uuid } from "uuidv4"

export interface TabsItemType {
  id: number
  route?: string
  tabLabel: string | JSX.Element
  tabComponent?: JSX.Element
}

interface TabsViewType extends React.PropsWithChildren {
  tabsArray: TabsItemType[]
  activeTabPosition?: number
  getActiveTabPosition?: (activeTab: number) => void
  isDisabled?: boolean
  tabContainerClass?: string
  tabClass?: string
  tabPanelClass?: string
  indicatorColor?: string
  separator?: JSX.Element
}

const TabsView: React.FC<TabsViewType> = ({ tabsArray, activeTabPosition = 0, getActiveTabPosition }): JSX.Element => {
  const navigate = useNavigate()
  const checkedRoutes = useSelector((state: RootState) => state.ui.routeInfo.checkedRoutes)
  const [activeTab, setActiveTab] = useState(activeTabPosition)

  // This is a patch for the time being to fix an issue with the EN Tabs

  const [tabsKey, setTabsKey] = useState(uuid())

  useEffect(() => {
    setTabsKey(uuid())
  }, [tabsArray])

  //--------------------------------------------------------------------

  useEffect(() => {
    setActiveTab(activeTabPosition)
  }, [activeTabPosition])

  const handleChange = (e: Event) => {
    const { activeTabIdx } = (e as CustomEvent).detail

    setActiveTab(activeTabIdx)
    const route = tabsArray?.find((item) => item.id === activeTabIdx)?.route
    if (route) {
      navigate(route || "")
    }
    getActiveTabPosition?.(activeTabIdx)
  }

  return (
    <ENTabs key={tabsKey} onTabChange={handleChange} activeIndex={activeTab}>
      {tabsArray.map(({ tabLabel, id }, index) => (
        <ENTab key={index}>
          {tabLabel}
          {checkedRoutes[id] && (
            <ZtnaIcon name="circleTick" color={themeData.palette.background.successDefault} height={16} width={16} />
          )}
        </ENTab>
      ))}

      {tabsArray.map((item, index) => (
        <ENTabPanel slot="panel" key={index}>
          {item.tabComponent}
        </ENTabPanel>
      ))}
    </ENTabs>
  )
}

export default TabsView
