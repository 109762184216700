import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import customParseFormat from "dayjs/plugin/customParseFormat"
import relativeTime from "dayjs/plugin/relativeTime"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import { LayoutPoints } from "src/constants/layout"
import { AuthenticationPiePayloadTypes } from "src/services/api/swrHooks/useAuthenticationTypesChart"
import { AnalyticsConnectionPayloadTypes } from "src/services/api/swrHooks/useDashboardAnalyticsConnect"
import { APPLICATION_PROTOCOLS_LIST, WEB_ACCESSIBLE_PROTOCOLS } from "./constants"
import { ServiceProtocolListType } from "./utils.types"

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)

type AnalyticsConnectionPayloadType = {
  dataPoints: {
    timestamp: string
    up?: number
    down?: number
    partiallyDown?: number
    total?: number
  }[]
}

type AnalyticsHostsRelayNodesPerformanceAnomiliesDataPointType = {
  timestamp: string
  value: number
  name: string
  connectorName: string
  instanceName: string
}

type AnalyticsHostsRelayNodesPerformanceAnomiliesPayloadType = {
  cpuPerformanceStats: { dataPoints: AnalyticsHostsRelayNodesPerformanceAnomiliesDataPointType[] }
  memoryPerformanceStats: { dataPoints: AnalyticsHostsRelayNodesPerformanceAnomiliesDataPointType[] }
}

type StatsType = {
  count: number
  upPercentage: number
  downPercentage: number
  upCount: number
  downCount: number
}

type ResourcesDistributionsPayloadType = {
  sites: StatsType
  hosts: StatsType
  relayNodes: StatsType
  services: StatsType
  projects: StatsType
}

const PERFORMANCE_INTERVAL = {
  today: 3600000,
  twentyFourHr: 360000,
  last24Hours: 360000,
  oneHr: 600000,
  twoHr: 600000,
  last1Hour: 600000,
  last2Hour: 600000,
  last7Days: 86400000,
  last30Days: 86400000,
}

export type ChartsTimeIntervalFilterType = keyof typeof PERFORMANCE_INTERVAL

export const formatTimePoints = ({
  x,
  y,
  filter,
}: {
  x?: string
  y?: number
  filter: ChartsTimeIntervalFilterType
}) => {
  const dateFormatMap = {
    today: "h A",
    twentyFourHr: "h A",
    last24Hours: "h A",
    oneHr: "H:mm A",
    twoHr: "H:mm A",
    last1Hour: "H:mm A",
    last2Hour: "H:mm A",
    last7Days: "DD/MM/YYYY",
    last30Days: "DD/MM/YYYY",
  }

  if (x === undefined || y === undefined) return { x: 0, y: 0 }

  const dateFormat = dateFormatMap[filter]

  if (dateFormat) return { x: dayjs.utc(x).local().format(dateFormat), y }

  return { x, y }
}

export const getInitials = (fullname?: string): string | undefined => {
  if (!fullname || typeof fullname !== "string") return undefined

  const names = fullname.split(" ")
  const firstChar = names[0].substring(0, 1).toUpperCase()
  const secondChar = names.length > 1 ? names[names.length - 1].substring(0, 1).toUpperCase() : ""

  const initials = `${firstChar}${secondChar}`

  return initials
}

export const getCookie = (cname: string): string => {
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(";")
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i]
    while (c.charAt(0) === " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

export const deleteCookie = (cname: string) => {
  document.cookie = `${cname}=; Max-Age=0`
}

const isArray = (data: any): boolean => {
  return Array.isArray(data)
}

const isObject = (data: any): boolean => {
  return data === Object(data) && !isArray(data) && typeof data !== "function"
}

export const toSnakeCase = (s: string) => s.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const addSpaceToKey = (s: string) => s.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)

export const toCamelCase = (s: string): string => {
  return s.replace(/([-_][a-z])/gi, (letter) => {
    return letter.toUpperCase().replace("-", "").replace("_", "")
  })
}

export const keysToCamelCase = (o: any) => {
  if (isObject(o)) {
    const n: any = {}

    Object.keys(o).forEach((k: string) => {
      n[toCamelCase(k)] = keysToCamelCase(o[k])
    })

    return n
  }
  if (isArray(o)) {
    return o.map((i: any) => {
      return keysToCamelCase(i)
    })
  }

  return o
}

export const keysToCapitialize = (obj: any) => {
  const objEntries = Object.entries(obj)
  const capsEntries = objEntries.map((entry) => [
    addSpaceToKey(entry[0]).replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
    entry[1],
  ])

  return Object.fromEntries(capsEntries)
}

export const keysToSnakeCase = (o: any) => {
  if (isObject(o)) {
    const n: any = {}

    Object.keys(o).forEach((k: string) => {
      n[toSnakeCase(k)] = keysToSnakeCase(o[k])
    })

    return n
  }
  if (isArray(o)) {
    return o.map((i: any) => {
      return keysToSnakeCase(i)
    })
  }

  return o
}

export const isInArray = (value: string | boolean, data: (string | boolean)[]): boolean => {
  const updatedValue = typeof value === "boolean" ? value : value?.toLowerCase()
  return data.some((element) => {
    const updatedElement = typeof element === "boolean" ? element : element?.toLowerCase()
    return updatedValue === updatedElement
  })
}

export const findData = ({ data, query, key = "name" }: { data: any; query?: string; key?: string }) => {
  if (!query) {
    return data
  }
  return data.filter((item: any) => {
    const valueToMatch = item && item[key]?.toLowerCase()
    const parsedQuery = query?.trim()?.toLowerCase()
    return valueToMatch.indexOf(parsedQuery) !== -1
  })
}

export const timestampLabelFormatting = (value: string | number, filter: ChartsTimeIntervalFilterType) => {
  if (filter === "last1Hour") {
    return dayjs(value).format("H:mm A")
  }
  if (filter === "last2Hour") {
    return dayjs(value).format("H:mm A")
  }
  if (filter === "last24Hours") {
    return dayjs(value).format("h A")
  }
  if (filter === "today") {
    return dayjs(value).format("h A")
  }
  if (filter === "last7Days") {
    return dayjs(value).format("DD/MM/YYYY")
  }
  if (filter === "last30Days") {
    return dayjs(value).format("DD/MM/YYYY")
  }

  return value
}

export const mapPerformanceAnomiliesData = (
  data: AnalyticsHostsRelayNodesPerformanceAnomiliesPayloadType | undefined,
) => {
  const cpuPoints = data?.cpuPerformanceStats?.dataPoints.map((item) => ({
    x: dayjs.utc(item.timestamp).local().valueOf(),
    y: item.value,
    connectorName: item.connectorName,
    instanceName: item.instanceName,
  }))

  const memoryPoints = data?.memoryPerformanceStats?.dataPoints.map((item) => ({
    x: dayjs.utc(item.timestamp).local().valueOf(),
    y: item.value,
    connectorName: item.connectorName,
    instanceName: item.instanceName,
  }))

  const chartData = {
    datasets: [
      { backgroundColor: "#f8b16f", label: "CPU", data: cpuPoints },
      {
        backgroundColor: "#ef817f",
        label: "Memory",
        data: memoryPoints,
      },
    ],
  }
  return chartData
}

const CONNECTION_UP_SERIES_CONFIG = {
  backgroundColor: "#66BC53",
  borderColor: "#66BC53",
  fill: "origin",
  label: "Up",
  data: [],
}

const CONNECTION_DOWN_SERIES_CONFIG = {
  backgroundColor: "#F44336",
  borderColor: "#F44336",
  fill: "origin",
  label: "Down",
  data: [],
}

const CONNECTION_PARTIALLY_DOWN_SERIES_CONFIG = {
  backgroundColor: "#b87a00",
  borderColor: "#b87a00",
  fill: "origin",
  label: "Partially Down",
  data: [],
}

export const mapNetworkDataLineCharttypes = ({
  data,
  filter,
  entityType,
}: {
  data?: AnalyticsConnectionPayloadTypes
  filter: ChartsTimeIntervalFilterType
  entityType: "Authentication" | "Network"
}) => {
  const keysSet = new Set()
  // eslint-disable-next-line array-callback-return
  data?.dataPoints?.map((item: any) => {
    if (item) Object.keys(item?.authTypes ?? item?.authStates).map((key) => keysSet.add(key))
  })
  const keysList = [...keysSet]
  const timezoneName = dayjs.tz.guess()
  const updatedData = data?.dataPoints?.map((trg) => {
    const duplicatTrg = { ...trg }
    duplicatTrg.timestamp = parseInt(dayjs.utc(duplicatTrg.timestamp).tz(timezoneName).format("x"))
    if (duplicatTrg?.authStates) {
      duplicatTrg.authStates = checkKeys(duplicatTrg.authStates, keysList)
    }
    if (duplicatTrg?.authTypes) {
      duplicatTrg.authTypes = checkKeys(duplicatTrg.authTypes, keysList)
    }
    return duplicatTrg
  })

  const result = {
    dataPoints: updatedData,
  }

  const series = keysList.map((value, index) => {
    const color = fetchColor(index)
    const points = result?.dataPoints?.map((point) => {
      let values = Object.values(point?.authTypes ?? point?.authStates)
      let sum = values.reduce((accumulator, value) => {
        return accumulator + value
      }, 0)
      let currentValue = point?.authTypes?.[value as string] || point?.authStates?.[value as string] || 0
      let pointPercentage = (currentValue / sum) * 100
      return {
        x: point?.timestamp,
        y: pointPercentage,
        z: point?.authTypes?.[value as number] || point?.authStates?.[value as number] || 0,
      }
    })
    return {
      type: "line",
      name: value === "PAP" || value === "EAP-TTLS" ? "802.1X - EAP-TTLS (PAP)" : value,
      color:
        value === "Accepted"
          ? "#5DC180"
          : value === "Disconnected"
          ? "#F9C56F"
          : value === "Rejected"
          ? "#FF8C85"
          : color,
      data: points,
    }
  })

  const chartData = series

  return chartData
}

export const mapDataDonutChartTypes = ({
  data,
  filter,
  entityType,
}: {
  data?: AuthenticationPiePayloadTypes
  filter: ChartsTimeIntervalFilterType
  entityType: "Analytics" | "Application"
}) => {
  let result = Object.fromEntries(Object.entries(data!).filter((e) => e[0] !== "total"))
  let dataset = []
  let i = 0
  for (const [key, value] of Object.entries(result!)) {
    let points = {
      name:
        key === "PAP" || key === "EAP-TTLS"
          ? "802.1X - EAP-TTLS (PAP)"
          : entityType === "Application"
          ? key === "telnet"
            ? getTitleCase(key)
            : key.toUpperCase()
          : key.charAt(0).toUpperCase() + key.slice(1),
      y: value,
      color: fetchColor(i),
    }
    i++
    dataset.push(points)
  }
  let series = [{ data: dataset }]

  const chartData = series
  return chartData
}

const checkKeys = (value: any, key: any) => {
  const dupObj = { ...value }
  key.forEach((key: any) => {
    if (!dupObj[key]) {
      dupObj[key] = 0
    }
  })
  return dupObj
}

export const fetchColor = (index: number) => {
  const colors = [
    "#A4DB98",
    "#8981E5",
    "#D099E4",
    "#FC9253",
    "#50BCC8",
    "#F9C56F",
    "#FF8C85",
    "#75BF63",
    "#ABA3FB",
    "#B575CC",
    "#165DFF",
    "#20C5BB",
    "#F8B16F",
    "#8F66C2",
    "#EB7A7A",
    "#118F7A",
    "#93A4C2",
    "#BA5700",
    "#74C274",
    "#722ED1",
    "#EBCD7A",
    "#AA336A",
    "#483248",
    "#915F6D",
  ]
  return colors[index]
}
export const mapConnectionData = (
  data: AnalyticsConnectionPayloadType | undefined,
  filter: ChartsTimeIntervalFilterType,
) => {
  const parsedDataPoints: {
    [key: string]: {
      up: number
      down: number
      partiallyDown: number
      timestampMillis: number
    }
  } = {}
  const upPoints: { x: string | number; y: number; timestampMillis: number }[] = []
  const downPoints: { x: string | number; y: number; timestampMillis: number }[] = []
  const partiallyDownPoints: { x: string | number; y: number; timestampMillis: number }[] = []

  /**
   * Step: 1 --> Convert all time data into hashmap
   */
  data?.dataPoints.forEach((item) => {
    const timeStampLabel = timestampLabelFormatting(item.timestamp, filter)
    if (!parsedDataPoints[timeStampLabel]) {
      parsedDataPoints[timeStampLabel] = {
        up: 0,
        down: 0,
        partiallyDown: 0,
        timestampMillis: dayjs(item.timestamp).valueOf(),
      }
    }
    if (item.up) {
      parsedDataPoints[timeStampLabel].up += item.up
    }
    if (item.down) {
      parsedDataPoints[timeStampLabel].down += item.down
    }
    if (item.partiallyDown) {
      parsedDataPoints[timeStampLabel].partiallyDown += item.partiallyDown
    }
  })

  /**
   * Step: 2 --> Make seperate series for up, down and partiallyDown points
   */
  Object.keys(parsedDataPoints).forEach((dataKey) => {
    upPoints.push({
      x: dataKey,
      y: parsedDataPoints[dataKey].up,
      timestampMillis: parsedDataPoints[dataKey].timestampMillis,
    })
    downPoints.push({
      x: dataKey,
      y: parsedDataPoints[dataKey].down,
      timestampMillis: parsedDataPoints[dataKey].timestampMillis,
    })
    partiallyDownPoints.push({
      x: dataKey,
      y: parsedDataPoints[dataKey].partiallyDown,
      timestampMillis: parsedDataPoints[dataKey].timestampMillis,
    })
  })

  const datasets = []

  /**
   * Step: 3 --> Push into datasets and then sort them in ascending order based on the unix timestamp
   */
  if (upPoints.length > 0) {
    datasets.push({
      ...CONNECTION_UP_SERIES_CONFIG,
      data: upPoints.sort((a, b) => a.timestampMillis - b.timestampMillis),
    })
  }
  if (downPoints.length > 0) {
    datasets.push({
      ...CONNECTION_DOWN_SERIES_CONFIG,
      data: downPoints.sort((a, b) => a.timestampMillis - b.timestampMillis),
    })
  }
  if (partiallyDownPoints.length > 0) {
    datasets.push({
      ...CONNECTION_PARTIALLY_DOWN_SERIES_CONFIG,
      data: partiallyDownPoints.sort((a, b) => a.timestampMillis - b.timestampMillis),
    })
  }

  return {
    datasets,
  }
}

export const mapResourceDistributionInfo = (data: ResourcesDistributionsPayloadType | undefined) => {
  const chartData = {
    datasets: [
      {
        label: "Count",
        data: [
          { x: "Sites", y: data?.sites?.count || 0 },
          { x: "Connectors", y: data?.hosts?.count || 0 },
          { x: "Relays", y: data?.relayNodes?.count || 0 },
          { x: "Services", y: data?.services?.count || 0 },
          { x: "Projects", y: data?.projects?.count || 0 },
        ],
        backgroundColor: "#d55c97",
        borderColor: "#d55c97",
        borderWidth: 1,
      },
    ],
  }
  return chartData
}

const DATA_USAGE_INTERVAL = {
  today: 600,
  twentyFourHr: 600,
  last24Hours: 600,
  oneHr: 60,
  twoHr: 60,
  last1Hour: 60,
  last2Hour: 60,
  last7Days: 43200,
  last30Days: 86400,
}

export const getTimeLineForDataUsageChart = (filterValue: ChartsTimeIntervalFilterType) => {
  let endTime = dayjs().utc().format("YYYY-MM-DD HH:mm:ss")
  let startTime = dayjs().startOf("day").utc().format("YYYY-MM-DD HH:mm:ss")
  const interval = DATA_USAGE_INTERVAL[filterValue]
  if (filterValue === "last24Hours") {
    startTime = dayjs().utc().subtract(24, "hour").format("YYYY-MM-DD HH:mm:ss")
  } else if (filterValue === "last7Days") {
    startTime = dayjs().utc().subtract(6, "day").format("YYYY-MM-DD HH:mm:ss")
  } else if (filterValue === "last30Days") {
    startTime = dayjs().utc().subtract(30, "day").format("YYYY-MM-DD HH:mm:ss")
  } else if (filterValue === "last1Hour") {
    startTime = dayjs().utc().subtract(1, "hour").format("YYYY-MM-DD HH:mm:ss")
  } else if (filterValue === "last2Hour") {
    startTime = dayjs().utc().subtract(2, "hour").format("YYYY-MM-DD HH:mm:ss")
  } else if (filterValue === "oneHr") {
    startTime = dayjs().utc().format("YYYY-MM-DD HH:mm:ss")
    endTime = dayjs().utc().add(1, "hour").format("YYYY-MM-DD HH:mm:ss")
  } else if (filterValue === "twoHr") {
    startTime = dayjs().utc().format("YYYY-MM-DD HH:mm:ss")
    endTime = dayjs().utc().add(2, "hour").format("YYYY-MM-DD HH:mm:ss")
  } else if (filterValue === "twentyFourHr") {
    startTime = dayjs().utc().format("YYYY-MM-DD HH:mm:ss")
    endTime = dayjs().utc().add(24, "hour").format("YYYY-MM-DD HH:mm:ss")
  }

  return {
    endTime,
    startTime,
    interval,
  }
}

export const calculatePercentage = (totalCount: number, count: number) => {
  if (totalCount === 0) {
    return 0
  }
  return (count / totalCount) * 100
}

export const percentCalculation = (total: number, value: number) => {
  if (total === 0) {
    return 0
  } else {
    const percentage = (value / total) * 100
    const roundedPercentage = Math.round(percentage * 10) / 10
    return roundedPercentage % 1 === 0 ? roundedPercentage.toFixed(0) : roundedPercentage.toFixed(1)
  }
}

export const formatBytes = (bytes: number, decimals = 0) => {
  if (!+bytes) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const getFilters = (filters: { key: string; value: string }[]): string => {
  let filtersString = ""
  if (!filters || !filters.length) {
    return filtersString
  }
  filters.forEach(({ key, value }, idx) => {
    const shouldAppendAnd = filters.length > idx + 1 ? "&" : ""
    if (value) filtersString += `${key}=${value}${shouldAppendAnd}`
  })

  return filtersString
}

export const getMaxLayoutYAxisPlacement = (layout: LayoutPoints[]) => {
  const yValues = layout.map((i) => i.y)

  return Math.max(...yValues)
}

type filterType = "expired" | "aboutToExpire" | "services" | "users"

type filterResponseType = "exclude_by" | "filter_by"
export const filterOption = (type: filterType): filterResponseType => {
  switch (type) {
    case "aboutToExpire":
      return "exclude_by"
    default:
      return "filter_by"
  }
}

export const getProtocolsList = (isAgentless: boolean): ServiceProtocolListType[] =>
  isAgentless
    ? APPLICATION_PROTOCOLS_LIST.map((protocol) =>
        WEB_ACCESSIBLE_PROTOCOLS.includes(protocol.value) ? protocol : { ...protocol, isDisabled: true },
      )
    : APPLICATION_PROTOCOLS_LIST

const ImageExtensions = ["jpeg", "jpg", "png", "bmp", "tif", "tiff"]

export const isImage = (fileExtension?: string): boolean => {
  if (!fileExtension) return false
  return ImageExtensions.includes(fileExtension)
}

export const mapObjectToDropdown = <DropDownType = {}, ObjectType = {}>(
  array: ObjectType[],
  dropdownArray: DropDownType[],
  keyToCompare: [keyof DropDownType, keyof ObjectType],
  merger?: (obj: ObjectType) => Partial<ObjectType>,
): (DropDownType | any)[] =>
  array
    .map((item) => {
      const current = dropdownArray.find((obj) => obj?.[keyToCompare[0]] === item?.[keyToCompare[1]])
      const keysToMerge = merger?.(item) || {}

      if (current) {
        return { ...current, ...keysToMerge }
      } else {
        return undefined
      }
    })
    .filter((el) => el !== undefined && el !== null)

export const getStringArrayToDropdown = <DropDownType = any>(
  array: (unknown | string)[],
  dropdownArray: DropDownType[],
  keyToCompare: keyof DropDownType,
  extra: Record<string, any> = {},
): (DropDownType | any)[] =>
  array
    .map((item) => {
      const current = dropdownArray.find((obj) => obj[keyToCompare] === item)
      if (current) {
        return { ...current, ...extra }
      } else {
        return undefined
      }
    })
    .filter((el) => el !== undefined && el !== null)

export const getTitleCase = (word: string): string => {
  const [firstLetter = "", ...rest] = word
  return `${firstLetter.toUpperCase()}${rest.join("").toLowerCase()}`
}

// Format a number by adding commas to it
export const formatNumber = (number: number) => number.toLocaleString()

export const isViewMore = (array: unknown[], max: number = 5) => {
  const maxDisplayedRecord = max
  return { showViewMore: array.length - max > 0, items: array?.slice(0, maxDisplayedRecord) }
}

export const isEmptyObject = (obj: Record<string, any>): boolean => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        if (!isEmptyObject(obj[key])) {
          return false
        }
      } else if (obj[key] !== undefined && obj[key] !== null && obj[key].length !== 0) {
        return false
      }
    }
  }
  return true
}

export const isIntegerWithNoLeadingZero = (strNumber: string) => {
  if (strNumber.startsWith("0") && strNumber !== "0") {
    return false // Has leading zero
  }

  return Number.isInteger(Number(strNumber))
}

export const getXiqUrl = () => {
  return `${localStorage.getItem("xiqUrl")}/login`
}

export const getExternalApiUrl = () => {
  return localStorage.getItem("xcdUrl")
}

export const isSomething = (data: any) => {
  if (data === null || data === undefined) return false

  if (typeof data === "number") return true

  if (typeof data === "string" && data.length === 0) return false

  if (typeof data === "object" && Object.keys(data).length === 0) return false

  if (Array.isArray(data) && data.length === 0) return false

  return true
}

export const capitalize = (str: string) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase())
}
