import { createUseStyles } from "react-jss"

export const useBadgeWithIconCellStyles = createUseStyles((theme) => ({
  badgeWrapper: {
    display: "flex",
    alignItems: "center",
  },
  badgeWrapperWithIcon: {
    marginLeft: 28,
  },
  clickableBadgeIcon: {
    padding: 9,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 8,
    paddingBottom: 8,
  },
  arrow: {
    color: theme.palette.primary.main,
  },
  defaultCursor: {
    cursor: "default",
  },
  badgeIcon: {
    display: "flex",
    marginLeft: 12,
    alignItems: "center",
    width: 15,
    height: 15,
  },
  badge: {
    height: "28px !important",
    borderRadius: "13px !important",
    minWidth: 124,
    "& span": {
      fontSize: 14,
      fontWeight: 600,
    },
    color: `${theme.palette.grey[50]} !important`,
    backgroundColor: `${theme.palette.grey[50]}1a !important`,
  },
  badgeSuccess: {
    color: "#008700 !important",
    backgroundColor: "#e5f3e5 !important",
  },
  badgeWarning: {
    color: "#b87a00 !important",
    backgroundColor: "#f8f1e5 !important",
  },
  badgeDanger: {
    color: `${theme.palette.error.main} !important`,
    backgroundColor: "#fde5e5 !important",
  },
  noBadge: {
    backgroundColor: "transparent !important",
  },
  badgeIndeterminate: {
    color: `${theme.palette.grey[50]} !important`,
    backgroundColor: `${theme.palette.grey[50]}1a !important`,
  },
}))
