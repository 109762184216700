import { Link } from "@material-ui/core"
import { ENDivider } from "en-react/dist/src/components/Divider"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { Fragment } from "react"
import { CatalogAppsPayloadType } from "src/services/api/swrHooks/useCatalogApps"
import BlankSlate from "src/shared/components/BlankSlate"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import ZtnaTooltip from "src/shared/components/ZtnaTooltip"
import theme from "src/theme"
import { useDiscoveryAppsListStyles } from "./DiscoveryAppsList.styles"

interface DiscoveryAppsListProps {
  apps: CatalogAppsPayloadType[]
}

const DiscoveryAppsList = ({ apps }: DiscoveryAppsListProps) => {
  const classes = useDiscoveryAppsListStyles()

  return (
    <div className={classes.appsList}>
      {!!apps.length ? (
        <>
          {apps.map((app) => (
            <Fragment key={`discovery-app-${app.id}`}>
              <div className={classes.appItem}>
                <div className={classes.appIconAndName}>
                  <div className={classes.appIcon}>
                    <span dangerouslySetInnerHTML={{ __html: app.appIcon }} />
                  </div>

                  <ENTextPassage>
                    <span className={classes.appName}>{app.appName}</span>
                  </ENTextPassage>
                </div>

                <div className={classes.iconsContainer}>
                  <ZtnaTooltip title={app.appTooltip} arrow>
                    <div className={classes.infoIconContainer}>
                      <ZtnaIcon name="infoCircle" color={theme.palette.grey.A200} height="20" width="20" />
                    </div>
                  </ZtnaTooltip>

                  <Link href={app.appUrl} target="_blank" className={classes.navIconContainer}>
                    <ZtnaIcon name="link" color={theme.palette.grey.A200} height="18" width="18" />
                  </Link>
                </div>
              </div>

              <ENDivider className={classes.appDivider} />
            </Fragment>
          ))}
        </>
      ) : (
        <BlankSlate height={0} className={classes.blankSlate} text="No apps available to show" />
      )}
    </div>
  )
}

export default DiscoveryAppsList
