import { createUseStyles } from "react-jss"
import { TOP_NAV_HEIGHT } from "src/constants/layout"
import { APP_MARGIN } from "src/utils/constants"

export const useTopNavStyles = createUseStyles((theme) => ({
  appBar: {
    position: "sticky",
    top: APP_MARGIN,
    zIndex: theme.zIndex.appBar,
    right: "unset",
    height: TOP_NAV_HEIGHT,
    backgroundColor: theme.palette.background.surfaceElevation2,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "var(--en-border-radius-lg)",
    paddingLeft: theme.spacing(2),
    paddingRight: 24,
    gridArea: "TopNav",
    "&:before": {
      content: "''",
      height: APP_MARGIN,
      width: "100%",
      position: "absolute",
      top: -APP_MARGIN,
      left: 0,
      backgroundColor: theme.palette.background.surfaceElevation0,
    },
  },
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    appBar: { paddingLeft: 10, paddingRight: 15 },
  },
  brand: {
    display: "flex",
    alignItems: "center",
  },
  brandLogo: {
    width: (props: { isMobile: boolean }) => (props.isMobile ? "" : "30%"),
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    gap: 5,
  },
  profileName: {
    fontWeight: 600,
    maxWidth: 250,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  profileEmail: {
    "& p": {
      maxWidth: 250,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      textAlign: "start",
    },
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 8,
    paddingBottom: 8,
    maxWidth: 230,
  },
  arrow: {
    color: theme.palette.primary.main,
  },
  avatarRoot: {
    minWidth: 150,
    height: TOP_NAV_HEIGHT,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.tertiary.main,
    },
  },
  avatar: {
    width: 25,
    height: 25,
    fontSize: 14,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
  },
  profileAvatar: {
    width: 38,
    height: 38,
    border: "1px solid #e2e2e2",
  },
  leftSpacing: {
    marginLeft: 12,
  },

  logout: {
    color: theme.palette.error.main,
    fontSize: 13,
  },
  needHelp: {
    textAlign: "center",
    "&:hover": { border: 0, backgroundColor: theme.palette.tertiary.main },
    width: 136,
  },
  noTextTransform: {
    border: 0,
    height: TOP_NAV_HEIGHT,
    width: "100%",
    fontSize: 14,
    fontWeight: 500,
    backgroundColor: "transparent",
    color: theme.palette.common.white,
    paddingLeft: 5,
    paddingRight: 5,
    "&:hover, &:active, ": { border: 0, backgroundColor: "transparent" },
  },
  viewProfile: {
    fontSize: 13,
  },
  feedbackIcon: {
    cursor: "pointer",
    height: TOP_NAV_HEIGHT,
    width: 57,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: theme.palette.tertiary.main,
    },
  },
  rolloutContainer: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    paddingLeft: 9,
    paddingRight: 14,
    "& p": {
      color: theme.palette.grey[100],
      fontSize: 14,
      paddingBottom: 2,
      paddingLeft: 5,
      textTransform: "capitalize",
    },
    "&.Mui-disabled": {
      "& p": {
        color: theme.palette.grey[50],
      },
    },
    "& #rolloutDot": {
      width: 7,
      height: 7,
      backgroundColor: theme.palette.error.main,
      borderRadius: "50%",
      marginBottom: 12,
      marginLeft: 2,
    },
    "&.MuiButtonBase-root.Mui-disabled": {
      "& #rolloutDot": {
        visibility: "hidden",
      },
    },
  },
  portalIcon: {
    cursor: "pointer",
    width: 60,
    height: TOP_NAV_HEIGHT,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: theme.palette.tertiary.main,
    },
  },
  divider: {
    backgroundColor: "#3e4d65",
  },
  drawerBtnContainer: {
    marginRight: 15,
    height: 47,
    width: 47,
    display: "flex",
    justifyContent: "center",
    alignItems: "end",
  },
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    drawerBtnContainer: { marginRight: 5 },
  },
  drawerControls: {
    display: "flex",
    gap: 20,
    alignItems: "center",
  },
  drawerBtn: {
    paddingLeft: 0,
    paddingRight: 0,
    height: 47,
    width: 47,
    color: theme.palette.common.white,
    "&:hover, &:active": {
      backgroundColor: theme.palette.tertiary.main,
    },
  },
  logoutBtn: {
    paddingLeft: 0,
    paddingRight: 0,
    height: 25,
    width: 25,
  },
  appsNavigation: {
    width: 40,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.tertiary.main,
    },
  },
  nameWrapper: {
    marginLeft: 12,
    marginRight: 12,
    cursor: "pointer",
  },
  avatarWrapper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& svg": {
      color: theme.palette.grey[500],
    },
    marginLeft: 15,
    marginRight: 15,
  },
  titleName: {
    color: theme.palette.grey[500],
    fontWeight: 600,
    maxWidth: 250,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  titleCompanyName: {
    color: theme.palette.secondary[600],
    maxWidth: 250,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  caret: {
    color: theme.palette.grey[500],
  },
  popOverBtn: {
    marginBottom: 5,
  },
  popOverOpen: {
    transform: "rotate(180deg)",
    marginTop: 10,
  },
  menuItem: {
    width: 200,
  },
  contextMenu: {
    position: "relative",
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
    zIndex: "var(--en-z-index-500)",
  },
  contextMenuTab: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  menuUserName: {
    fontSize: "var(--en-font-size-20)",
    lineHeight: "var(--en-line-height-3)",
  },
  menuEmail: {
    fontSize: "var(--en-font-size-16)",
    lineHeight: "var(--en-line-height-2)",
  },
  userProfileImage: {
    width: 40,
    height: 40,
    borderRadius: "var(--en-border-radius-round)",
  },
  logoutItem: {
    display: "flex",
    alignItems: "center",
    gap: 12,
  },
  helpButton: {
    marginRight: -10,
  },
  helpIcon: {
    color: theme.palette.content.default,
  },
}))
