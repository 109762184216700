import clsx from "clsx"
import { ENListItem } from "en-react/dist/src/components/ListItem"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { Fragment } from "react"
import { useSelector } from "react-redux"
import { RouteType } from "src/routes/config"
import ZtnaIcon from "src/shared/components/Icons"
import ZtnaTooltip from "src/shared/components/ZtnaTooltip"
import { RootState } from "src/store"
import { themeData as theme } from "src/theme"
import { useSideNavStyles } from "./SideNav.styles"

type NavItemType = {
  route: RouteType
  selectedRoute?: string
  isChild?: boolean
  handleNavItemClick: (route: RouteType) => void
  popOverMenu?: boolean
  className?: string
}

export const NavItem: React.FC<NavItemType> = ({
  route,
  selectedRoute,
  isChild,
  handleNavItemClick,
  popOverMenu,
  className,
}) => {
  const { id, title = "", iconName } = route
  const classes = useSideNavStyles({ iconName })
  const selected = selectedRoute === id
  const isOpened = useSelector((state: RootState) => state.ui.sideNav.isOpened)
  const titleForId = title.replaceAll(" ", "-")

  const renderListItem = () => {
    return (
      <ENListItem
        id={`en-ztna-NavItem-${titleForId}-${id}`}
        onClick={() => handleNavItemClick(route)}
        isCurrent={false}
      >
        <div className={classes.navItemTitleContainer}>
          {iconName && (
            <ZtnaIcon
              name={iconName}
              size="lg"
              color={selected ? theme.palette.background.accentDefault : theme.palette.background.inverseEmphasis}
              style={{
                color: selected ? theme.palette.background.accentDefault : theme.palette.background.inverseEmphasis,
              }}
            />
          )}
          {(isChild || isOpened) && (
            <ENTextPassage>
              <span className={clsx({ [classes.selectedText]: selected }, classes.navItemTitle)}>{title}</span>
            </ENTextPassage>
          )}
        </div>
      </ENListItem>
    )
  }

  return (
    <Fragment key={`NavItem-${title}-${id}`}>
      {isOpened && <>{renderListItem()}</>}
      {!isOpened && (
        <>
          {isChild && renderListItem()}
          {!isChild && (
            <ZtnaTooltip title={title} placement="right" arrow>
              {renderListItem()}
            </ZtnaTooltip>
          )}
        </>
      )}
      {/* {!isChild && isOpened && <ENDivider className={classes.listDivider} />} */}
    </Fragment>
  )
}
