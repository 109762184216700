import { ColDef } from "ag-grid-community"
import { isEqual } from "lodash"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import DataGrid from "src/components/DataGrid/DataGrid"
import BadgeWithIcons from "src/components/DataGrid/renderers/BadgeWithIconsCell/BadgeWithIconCell"
import usePaginationData from "src/hooks/usePaginationData"
import useHosts, { HostsPayloadType, HostUiStatus } from "src/services/api/swrHooks/useHosts"
import Modal from "src/shared/components/Modal"
import { closeModal } from "src/store/ui"
import ConnectorExpandedInstancesView from "./ConnectorExpandedInstancesView"
import { useConnectorsUpgradeDetailsModalStyles } from "./ConnectorsUpgradeDetailsModal.styles"

const ConnectorsUpgradeDetailsModal = () => {
  const classes = useConnectorsUpgradeDetailsModalStyles()

  const dispatch = useDispatch()
  const onClose = () => dispatch(closeModal("upgradeConnectorDetails"))

  const colDefs: ColDef[] = [
    { field: "name", headerName: "Connector Name", width: 410 },
    {
      field: "hostStatus",
      headerName: "Status",
      cellRenderer: BadgeWithIcons,
      cellRendererParams: {
        badgeConfig: {
          alignChild: "center",
          align: "center",
          successValues: [HostUiStatus.UP],
          warningValues: [HostUiStatus.ENABLING_HA, HostUiStatus.DISABLING_HA],
          dangerValues: [HostUiStatus.DOWN],
          indeterminateValues: [HostUiStatus.NA],
          badgeTooltipKey: "hostStatusTooltip",
          showTooltip: true,
        },
      },
    },
  ]

  const [tableData, setTableData] = useState<HostsPayloadType>({ hosts: null, total: 0, from: 0, to: 0 })

  const { limit, offset } = usePaginationData()

  const { data, isLoading, isValidating, getHosts } = useHosts({ limit, offset, refreshInterval: 0 })

  useEffect(() => {
    if (!isValidating && data?.hosts && !isEqual(tableData.hosts, data.hosts)) {
      setTableData(data)
    }
  }, [isValidating])

  return (
    <Modal
      title="Service Connectors Upgrade Progress"
      onOk={onClose}
      onOkProps={{ title: "Close" }}
      onCancel={onClose}
      onCancelProps={{ visible: false }}
      contentClass={classes.content}
      width={863}
    >
      <DataGrid
        columnDefs={colDefs}
        rowData={tableData.hosts}
        showLoading={isLoading || isValidating}
        onGridReady={(params) => params.api.sizeColumnsToFit()}
        containerHeight={400}
        hasFilters={false}
        isExpandable
        applyCollapsibleWrapper
        expandableRowComponent={(params: any) => (
          <ConnectorExpandedInstancesView hostData={params.data} getServiceConnectors={getHosts} />
        )}
      />
    </Modal>
  )
}

export default ConnectorsUpgradeDetailsModal
