// import { MasterDetailModule } from "@ag-grid-enterprise/master-detail"
// import { ModuleRegistry } from "ag-grid-community/core"
import "ag-grid-community/styles/ag-grid.css" // Core CS
import { LicenseManager } from "ag-grid-enterprise/"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import store from "src/store"
import { SWRConfig } from "swr"
import "../node_modules/en-web-components/dist/en/scss/ag-grid.scss"
import App from "./App"
import LoggerWrapper from "./components/LoggerWrapper"
import "./index.scss"
import reportWebVitals from "./reportWebVitals"
import SWRGlobalConfig from "./services/api/swrHooks/SWRGlobalConfig"

LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-050616}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Extreme_Networks,_Inc}_is_granted_a_{Multiple_Applications}_Developer_License_for_{59}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{24}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{21_February_2025}____[v3]_[01]_MTc0MDA5NjAwMDAwMA==d6f01dcc1ae5c98e2a1202b68963b3c5",
)

// ModuleRegistry.registerModules([MasterDetailModule])

require("typeface-open-sans")

// Mocking unavailable CRaaS APIs in prod environment, to be removed when we have the actual data
if (process.env.NODE_ENV === "test") {
  const { craasTempProductionWorker } = require("src/craas/mocks/browser")
  craasTempProductionWorker.start()
}

// if (process.env.NODE_ENV === "development") {
//   const { worker } = require("src/craas/mocks/browser")
//   worker.start()
// }

const root = createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <Provider store={store}>
    <LoggerWrapper>
      <SWRConfig value={SWRGlobalConfig}>
        <App />
      </SWRConfig>
    </LoggerWrapper>
  </Provider>,
)

// ReactDOM.render(
//   <Provider store={store}>
//     <LoggerWrapper>
//       <SWRConfig value={SWRGlobalConfig}>
//         <App />
//       </SWRConfig>
//     </LoggerWrapper>
//   </Provider>,
//   document.getElementById("root"),
// )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
