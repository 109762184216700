import { Drawer, Link } from "@material-ui/core"
import { ENDivider } from "en-react/dist/src/components/Divider"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import React from "react"
import { useDispatch } from "react-redux"
import { XIQ_DOMAIN } from "src/constants"
import { PrivateRoutesMap } from "src/routes/config"
import useCatalogApps from "src/services/api/swrHooks/useCatalogApps"
import ErrorSlate from "src/shared/components/ErrorSlate"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import LoadingSlate from "src/shared/components/LoadingSlate"
import { closeDrawer } from "src/store/ui"
import { useDiscoveryAppsDrawerStyles } from "./DiscoveryAppsDrawer.styles"
import DiscoveryAppsList from "./DiscoveryAppsList"

const DiscoveryAppsDrawer: React.FC = () => {
  const classes = useDiscoveryAppsDrawerStyles()

  const dispatch = useDispatch()
  const onClose = () => dispatch(closeDrawer())

  const { data, error, isLoading } = useCatalogApps()
  const myApps = data.filter((app) => app.appLicense)
  const otherApps = data.filter((app) => !app.appLicense)

  return (
    <Drawer anchor="right" open onClose={onClose} className={classes.drawer}>
      <ENTextPassage>
        <span className={classes.title}>Discovery Apps</span>
      </ENTextPassage>
      <ENDivider className={classes.divider} />

      {isLoading ? (
        <div className={classes.loaderErrorWrapper}>
          <LoadingSlate />
        </div>
      ) : error ? (
        <div className={classes.loaderErrorWrapper}>
          <ErrorSlate iconHeight="60px" iconWidth="60px" />
        </div>
      ) : (
        <>
          <ENTextPassage>
            <span className={classes.appsTitle}>
              My Apps
              <Link
                href={`${window.location.protocol}${XIQ_DOMAIN}${PrivateRoutesMap.DISCOVERY_APPS.absolutePath}`}
                target="_blank"
                className={classes.navIconButton}
              >
                <ZtnaIcon name="link" color="#007E91" />
              </Link>
            </span>
          </ENTextPassage>

          <DiscoveryAppsList apps={myApps} />

          <ENDivider className={classes.divider} />

          <ENTextPassage>
            <span className={classes.appsTitle}>Other Apps</span>
          </ENTextPassage>

          <DiscoveryAppsList apps={otherApps} />
        </>
      )}
    </Drawer>
  )
}

export default DiscoveryAppsDrawer
