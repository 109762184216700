import { createUseStyles } from "react-jss"

export const useDataGridStyles = createUseStyles((theme) => ({
  tableTaskBar: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 20px",
    borderRadius: "8px 8px 0 0",
    border: `1px solid ${theme.palette.background.surfaceElevation3}`,
    alignItems: "center",
    minHeight: 64,
    gap: 40,
  },
  searchBar: {
    width: 400,
  },
  rightAligner: {
    marginLeft: "auto",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  actionBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 20px",
    minHeight: 35,
  },
  marginRight: {
    marginRight: 10,
  },
}))
