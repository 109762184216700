import clsx from "clsx"
import { ENChip } from "en-react/dist/src/components/Chip"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { addEllipsis } from "src/craas/utils"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import ZtnaTooltip from "src/shared/components/ZtnaTooltip"
import { CellRendererProps } from "../../DataGrid.types"
import useStatusCellStyles from "./StatusCell.styles"

type StatusBadgeText = "successText" | "dangerText"

const statusMap = {
  success: "Up",
  danger: "Down",
  warning: "",
  hidden: "",
}

const StatusCell: React.FC<CellRendererProps> = ({ data, colDef }) => {
  const classes = useStatusCellStyles()

  const { cellRendererParams } = colDef
  const { config, activeSinceConfig } = cellRendererParams

  const activeSinceInnerData = data[activeSinceConfig?.activeSinceKey]
  const secondaryIconNameKey = config?.postIconConfig?.secondaryIconConfig?.nameKey
  const secondaryIconTooltipKey = config?.postIconConfig?.secondaryIconConfig?.tooltipKey

  const status =
    config.successValue === "" && config.dangerValue === "" && config.warningValue === ""
      ? "hidden"
      : data[config.key] === config.successValue
      ? "success"
      : data[config.key] === config.dangerValue
      ? "danger"
      : "warning"

  const statusText: StatusBadgeText = `${status}Text` as StatusBadgeText

  const mainText =
    colDef.field && (data[colDef.field] || data[colDef.field] === 0)
      ? (`${data[colDef.field]}` as string)
      : config?.fallback /* || children */

  const displayText = config?.textLengthForEllipsis
    ? addEllipsis({ text: mainText, count: config.textLengthForEllipsis })
    : mainText

  const showStatusDot = config?.showStatusDot === undefined ? true : config?.showStatusDot

  return (
    <>
      <div className={classes.root}>
        {showStatusDot && <div id={config?.id} className={clsx(classes.dot, classes[status])} />}

        {mainText && (
          <>
            {config?.warning || config?.postIconWarning || config?.showTooltip ? (
              <ZtnaTooltip title={mainText} placement="bottom">
                <ENTextPassage
                  // noWrap
                  data-testid="label-with-tooltip"
                  className={clsx(classes.text, {
                    [classes[statusText]]: config?.isColoredText,
                    [classes.text]: config?.showTooltip,
                  })}
                >
                  {displayText}
                </ENTextPassage>
              </ZtnaTooltip>
            ) : (
              <ENTextPassage
                /* noWrap */ className={clsx(classes.text, { [classes[statusText]]: config?.isColoredText })}
              >
                {displayText}
              </ENTextPassage>
            )}
          </>
        )}

        {config?.warning && (
          <ZtnaTooltip
            title={<div dangerouslySetInnerHTML={{ __html: config?.warning }} />}
            arrow
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
          >
            <div data-testid="warning-icon" className={classes.warnFilledIconContainer}>
              <ZtnaIcon name="triangleWarning" />
            </div>
          </ZtnaTooltip>
        )}

        {config?.postText && <ENChip className={classes.postBadge}>{config.postText}</ENChip>}

        {config?.postIconWarning && (
          <ZtnaTooltip
            title={config.postIconWarning}
            arrow
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
          >
            <div data-testid="postIconWarning-icon" className={classes.postIconContainer}>
              <ZtnaIcon
                name={config.postIconConfig?.name || "info"}
                color={config.postIconConfig?.color || "#e2b100"}
              />
            </div>
          </ZtnaTooltip>
        )}

        {secondaryIconNameKey && data?.[secondaryIconNameKey] && (
          <ZtnaTooltip
            title={secondaryIconTooltipKey && data[secondaryIconTooltipKey] ? data[secondaryIconTooltipKey] : ""}
            arrow
            classes={{ tooltip: clsx(classes.tooltip, classes.secondaryIconTooltip), arrow: classes.arrow }}
            placement="bottom"
          >
            <div className={classes.secondaryIconContainer}>
              <ZtnaIcon name={data[secondaryIconNameKey]} />
            </div>
          </ZtnaTooltip>
        )}

        {activeSinceConfig?.activeSinceStyle === "inLine" && (
          <span className={classes.activeSinceText}>{`${statusMap[status]} since ${activeSinceInnerData}`}</span>
        )}
      </div>

      {activeSinceConfig?.activeSinceStyle === "newLine" && (
        <span className={classes.activeSinceText}>{`${statusMap[status]} since ${activeSinceInnerData}`}</span>
      )}
    </>
  )
}

export default StatusCell
